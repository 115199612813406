<template>
  <div class="saml-container">
    <p class="saml-forn-header">Configure SAML Single Sign On</p>
    <div class="saml-form">
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop">
          <input-vue
            ref="audience"
            :required="false"
            :min="1"
            :readonly="true"
            :value="saml.details.audienceURI"
            type="text"
            :max="150"
            :submitted="submitted"
            @onChangeName="onChangeAudienceURI"
            label="Audience URI (SP Entity ID)" />
          <div class="copy-button">
            <b-button @click="copy('audienceID')">
            <b-icon icon="content-copy" size="is-small"> </b-icon>
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop">
          <input-vue
            ref="sso"
            :required="false"
            :min="1"
            :value="saml.details.ssoURL"
            type="text"
            :readonly="true"
            :max="150"
            :submitted="submitted"
            @onChangeName="onChangeSsoURL"
            label="Single sign on URL" />
          <div class="copy-button">
            <b-button @click="copy('ssoURL')">
            <b-icon icon="content-copy" size="is-small"> </b-icon>
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop">
          <input-vue
            :required="true"
            :min="1"
            :value="saml.details.issuerURL"
            type="url"
            :max="150"
            :submitted="submitted"
            @onChangeName="onChangeIssuerURL"
            label="Issuer URI (IDP Entity ID)" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop">
          <input-vue
            :required="true"
            :min="1"
            :value="saml.details.loginURL"
            type="url"
            :max="150"
            :submitted="submitted"
            @onChangeName="onChangeLoginURL"
            label="Login URL" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop saml-textarea">
          <input-vue
            :required="true"
            :min="1"
            :value="saml.details.cert"
            type="textarea"
            :max="Infinity"
            :submitted="submitted"
            @onChangeName="onChangeCert"
            label="IDP Public Certificate" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile is-10-tablet is-6-desktop">
                      <div class="align-options">

      <div
        class="option-cover"
        v-for="(topic, index) of ssoAllowUser"
        :key="index">
        <radio-vue
          :selected="saml.allowAllUser"
          @onChange="onChangeOption"
          :value="topic" />
        <p  @click="onChangeOption(topic)">{{ topic.type }}</p>
      </div>
                      </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="submit-button">
            <b-button @click.prevent="handleSubmit">{{
              this.saml.ssoId != null ? 'Update' : 'Add'
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ssoAllowUser } from '../../../../constant/data'
import InputVue from '../../../Admin/components/Input.vue'
import RadioVue from '../../../Admin/components/Radio.vue'

export default {
  name: 'SAML',
  components: {
    InputVue,
    RadioVue
  },
  data () {
    return {
      ssoAllowUser,
      saml: {
        details: {
          audienceURI: null,
          ssoURL: null,
          issuerURL: null,
          loginURL: null,
          cert: null
        },
        allowAllUser: ssoAllowUser[0],
        ssoId: null,
        type: 'Okta'
      },
      isIssuerURLInvalid: false,
      isLoginURLInvalid: false,
      isCertInvalid: false,
      submitted: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('saml', ['ssoDetails'])
  },
  watch: {
    ssoDetails () {
      if (this.ssoDetails) {
        this.saml.details = this.ssoDetails.details
        this.saml.ssoId = this.ssoDetails.sso_id
        this.saml.type = this.ssoDetails.sso_type
        this.saml.allowAllUser = (this.ssoDetails.details.allow_all_user === undefined || this.ssoDetails.details.allow_all_user) ? ssoAllowUser[0] : ssoAllowUser[1]
      }
    }
  },
  mounted () {
    this.saml.details.audienceURI = `${window.location.origin}/api/v1/login/saml`
    this.saml.details.ssoURL = `${window.location.origin}/api/v1/login/saml/consume`
    this.getSamlDetails(this.userDetails.clientId)
  },
  methods: {
    ...mapActions('saml', ['addSAML', 'getSamlDetails', 'updateSAML', 'removeSSO']),
    onChangeAudienceURI (name, isValid) {
      this.saml.details.audienceURI = name
    },
    onChangeOption (value) {
      this.saml.allowAllUser = value
    },
    onChangeSsoURL (name, isValid) {
      this.saml.details.ssoURL = name
    },
    onChangeIssuerURL (name, isValid) {
      this.saml.details.issuerURL = name
      this.isIssuerURLInvalid = isValid
    },
    onChangeLoginURL (name, isValid) {
      this.saml.details.loginURL = name
      this.isLoginURLInvalid = isValid
    },
    onChangeCert (name, isValid) {
      this.saml.details.cert = name
      this.isCertInvalid = isValid
    },
    copy (type) {
      let copy
      switch (type) {
        case 'audienceID':
          copy = this.$refs.audience.$el.querySelector('input')
          break
        case 'ssoURL':
          copy = this.$refs.sso.$el.querySelector('input')
          break
      }
      copy.setAttribute('type', 'text')
      copy.select()
      document.execCommand('copy')
    },
    handleSubmit (e) {
      this.submitted = true
      if (
        !(
          this.isIssuerURLInvalid ||
          this.isLoginURLInvalid ||
          this.isCertInvalid
        )
      ) {
        if (this.saml.ssoId !== null) {
          this.updateSAML({
            saml: this.saml,
            clientId: this.userDetails.clientId
          })
        } else {
          this.addSAML({ saml: this.saml, clientId: this.userDetails.clientId })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.saml-container {
    padding-top: 30px;
  .saml-forn-header {
    color: var(--secondary-text-color);
    font-size: 16px;
    font-family: 'ROBOTO';
    font-weight: 500;
  }
  .saml-form {
    .columns {
      .column {
        position: relative;
        padding: 10px 10px 10px 0px;
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 10px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
          }
        }
        .copy-button {
          position: absolute;
          right: 15px;
          bottom: 15px;
          .button {
            height: 100%;
            padding: 0px;
            width: 20px;
            background: transparent;
            color: #7f8fa4;
            border: none
          }
        }
        .align-options {
          display: flex;
        .option-cover {
            display: flex;
            align-items: center;
            padding-right: 20px;
            cursor: pointer;
            p {
              padding-left: 10px;
              font-size: 14px;
              font-family: Quicksand;
              letter-spacing: 0px;
              color: #c8c8c8;
              opacity: 1;
            }
          }
      }
      }
    }
  }
}

::v-deep .form-group .control {
  background-color: #C4C8F829;
  border: 1px solid #35405229;
}
</style>
