<template>
  <div class="sso-main">
  <div class="sso-container">
    <div class="sso-top-list">
      <p class="sso-top-header">Single Sign-On (SSO) Provider</p>
      <div class="sso-list">
      <label v-for="option of [{name:'No SSO Provider'},{name:'SAML'}]" :key="option.name" class="custom-radio">
        <input type="radio" :id="option.name" :value="option.name" v-model="selected" @change="handleSSOOptionChange" />
        <div class="radio-checkmark"></div>
        <p class="sso-options">{{option.name}}</p>
      </label>
      </div>
    </div>
    <SAML v-if="selected=='SAML'" />
  </div>
  <modal-vue :modal="openSSORemovalConfirmation" @onChange="handleSSORemovalConfirmationModalChange">
      <RemoveSSOConfirmation :ssoInfo="ssoInfo" @cancel="handleSSORemovalConfirmationModalChange" @confirmSSORemoval="handleConfirmSSORemoval" />
    </modal-vue>
  </div>
</template>

<script>
import SAML from './SAML.vue'
import { metaTitle } from '../../../../constant/data'
import { mapActions, mapState } from 'vuex'
import RemoveSSOConfirmation from './RemoveSSOConfirmation.vue'
import ModalVue from '../../../Admin/components/Modal.vue'

export default {
  name: 'SSO',
  components: {
    SAML,
    RemoveSSOConfirmation,
    ModalVue
  },
  metaInfo () {
    return {
      title: metaTitle.security
    }
  },
  data () {
    return {
      selected: 'No SSO Provider',
      openSSORemovalConfirmation: false,
      confirmSSORemoval: false,
      ssoInfo: {
        ssoId: null,
        clientId: null
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('saml', ['ssoDetails'])
  },
  watch: {
    ssoDetails () {
      if (this.ssoDetails) {
        this.ssoInfo.ssoId = this.ssoDetails.sso_id
        this.ssoInfo.clientId = this.userDetails.clientId
        this.selected = 'SAML'
      }
    }
  },
  mounted () {
    this.getSamlDetails(this.userDetails.clientId)
  },
  methods: {
    ...mapActions('saml', ['getSamlDetails', 'removeSSO']),
    async handleConfirmSSORemoval (ssoInfo) {
      const result = await this.removeSSO(ssoInfo)
      if (result === 200) {
        this.selected = 'No SSO Provider'
        this.openSSORemovalConfirmation = false
      }
    },
    handleSSORemovalConfirmationModalChange (value) {
      this.openSSORemovalConfirmation = value
      if (value === false && this.ssoDetails) {
        this.selected = 'SAML'
      }
    },
    handleSSOOptionChange () {
      if (this.selected === 'No SSO Provider' && this.ssoDetails) {
        this.openSSORemovalConfirmation = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sso-main {
  .default-page {
  padding-left: 0px;
  }
  .sso-container {
  margin-top: 20px;
  background: var(--primary);
  border-radius: 4px;
  padding: 20px;
  .sso-top-list {
    // padding: 10px;
    .sso-list {
      padding-top: 10px;

      .sso-options {
        color: var(--secondary-text-color);
      font-size: 14px;
      font-family: 'ROBOTO';
      padding-left: 5px;
    }
    }
    .sso-top-header {
      font-size: 14px;
      font-family: 'ROBOTO';
      font-weight: 500;
      color: var(--secondary-text-color);
    }

    .custom-radio {
      padding: 5px 0px 0px;
    }
  }
}
}

</style>
