<template>
  <div class="remove-sso-modal">
    <div class="remove-sso-modal-container">
      <div class="modal-data">
        <p>
          Selecting <b>No SSO Provider</b> will remove the existing SAML connection. Are you sure you want to go ahead with this?
        </p>
        <br>
        <p>Type <i>confirm</i> in below field to remove the existing SSO connection.</p>
        <br>
        <input-vue
          :value="confirmText"
          :submitted="submitted"
          :customBorder="true"
          placeholder="Confirm Text"
          @onChangeName="onChangeName"
          type="text" />
        <div
          v-if="submitted && !isConfirmTextValid"
          class="required-error">
          {{errorMessage}}
        </div>
        <div class="actions mt-4">
          <b-button @click="handleCancel">Cancel</b-button>
          <b-button @click="onConfirmSSORemoval">Delete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputVue from '../../../Admin/components/Input.vue'

/**
 * The modal that pops up to confirm change in SSO option causing deletion of the SAML record.
 * @displayName RemoveSSOConfirmation
 */
export default {
  name: 'RemoveSSOConfirmation',
  components: {
    InputVue
  },
  props: {
    /**
     * The object containing the SSO Info which is to be deleted.
     */
    ssoInfo: {
      type: Object
    }
  },
  data () {
    return {
      confirmText: '',
      isConfirmTextValid: false,
      submitted: false,
      errorMessage: ''
    }
  },
  methods: {
    /**
     * This function gets called on cofirmation text input, and checks if the input is valid.
     * @param {String} data
     * @public
     */
    onChangeName (name) {
      this.confirmText = name
      // this.isConfirmTextValid = isValid
      if (this.confirmText !== 'confirm') {
        this.errorMessage = 'Please enter valid input'
        this.isConfirmTextValid = false
      } else {
        this.errorMessage = ''
        this.isConfirmTextValid = true
      }
    },
    /**
     * This function gets called on click of cancel button. This function emits the 'cancle' event.
     * @public
     */
    handleCancel () {
      this.$emit('cancel', false)
    },
    /**
     * This function gets called on click of delete button. This function emits the 'confirmSSORemoval' event.
     * @param {String} data
     * @public
     */
    onConfirmSSORemoval () {
      this.submitted = true
      if (this.isConfirmTextValid) {
        this.$emit('confirmSSORemoval', this.ssoInfo)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .remove-sso-modal {
    display: flex;
    justify-content: center;
    color: var(--secondary-text-color);
    .remove-sso-modal-container {
      display: flex;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      // height: 194px;
      padding: 25px;
      .modal-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        font-size: 14px;
        font-family: roboto;
        .required-error {
          color: #ff0000;
          // position: absolute;
          font-size: 12px;
        }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }
    }
    .actions {
      padding-top: 10px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7f8fa466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #ff0000;
        color: white;
        &:hover {
          background-color: #f64a4a;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #ee1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
</style>
